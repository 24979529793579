<template>
  <div>
    <!-- 头图 -->
    <div class="merchantSettled">
      <div>
        <img src="@/assets/img/MerchantSettled/bannerbg_sjglptai2.jpg" />
      </div>
      <div class="merchantSettled_box">
        <div class="merchantSettled_left">
          <div class="merchantSettled_leftTitle">
            <div>商家管理平台</div>
            <div>Merchant login platform</div>
          </div>
          <!-- <div class="merchantSettled_leftBtn">
            <div>
              <div>申请入驻</div>
            </div>
            <div>
              <div>商家登录</div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- 流程 -->
    <div class="process">
      <div class="process_left">
        <div>
          <div class="process_leftImg">
            <img src="@/assets/img/MerchantSettled/ic_glswu.png" />
          </div>
          <div class="process_leftInfo">
            <div>关联小区食物</div>
            <div>
              与冰眸用户直接联动，提供服务给小区住户，平台会根据用户的食物消耗推荐，销售目标直达
            </div>
          </div>
        </div>
        <div>
          <img src="@/assets/img/MerchantSettled/pic_glswu.png" />
        </div>
      </div>
      <div class="process_right">
        <div>
          <img src="@/assets/img/MerchantSettled/pic_tszzhuan.png" />
        </div>
        <div>
          <div class="process_rightInfo">
            <div>提升食物周转</div>
            <div>
              多端应用，搭配手机app更高效移动处理订单、上架、盘点，随时随地管理店铺，网页、app数据实时同步
            </div>
          </div>
          <div class="process_rightImg">
            <img src="@/assets/img/MerchantSettled/ic_tszzhuan.png" />
          </div>
        </div>
      </div>
      <div class="process_left">
        <div>
          <div class="process_leftImg">
            <img src="@/assets/img/MerchantSettled/ic_sxfbian.png" />
          </div>
          <div class="process_leftInfo">
            <div>省心方便实惠</div>
            <div>
              免安装、免维护，终身免费升级，降低店铺信息化成本，数据安全可靠，免费移动进销存管理系统
            </div>
          </div>
        </div>
        <div>
          <img src="@/assets/img/MerchantSettled/pic_sxfbian.png" />
        </div>
      </div>
    </div>
    <!-- 申请 -->
    <!-- <div class="application">
      <div>
        <div class="application_title">
          <div>免费试用</div>
          <div>免费注册试用，如需交易请提交审核文件上</div>
        </div>
        <div class="application_box">
          <div class="application_boxLeft">
            <div>
              <div class="application_boxLeftItem">
                <div>店铺名称：</div>
                <input type="text" />
              </div>
              <div class="application_boxLeftItem">
                <div>用户名：</div>
                <input type="text" />
              </div>
              <div class="application_boxLeftItem">
                <div>手机号码：</div>
                <input type="text" />
              </div>
              <div class="application_boxLeftItem">
                <div>联系人：</div>
                <input type="text" />
              </div>
              <div class="application_boxLeftItem">
                <div>邮箱：</div>
                <input type="text" />
              </div>
            </div>
            <div class="application_boxLeftBtn">
              <div>提交注册</div>
            </div>
          </div>
          <div class="application_boxRight">
            一、预包装/散装商品，须提供：<br />
            1.营业执照；<br />
            2.《食品经营许可证》或《食品生产许可证》；<br />
            3.经营预包装食品：营业执照住所在自贸区内的卖家，可提供上述资质或提供经营范围包含食品经营的营业执照及监管部门要求的其他文件（如：预包装食品销售备案通知书等）；<br />
            二、小作坊制售商品，须持有营业执照和小作坊登记证；<br />
            三、食品添加剂生产厂家，须持有营业执照和食品添加剂生产许可证。<br />
            四、特许养殖类商品，须提供：<br />
            1.发布的肉类商品，须提供《野生动物经营利用许可证》。<br />
            2.发布水产类商品，须提供《水生野生动物经营利用许可证》。<br />
            3.本平台暂时不支持饮用酒类的销售，料酒及其他用途酒或含酒精类产吕须标明其他用途。
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
/* 头图 */
.merchantSettled {
  height: 150px;
  position: relative;
  display: flex;
}
.merchantSettled > div:nth-of-type(1) img {
  width: 100%;
  height: 100%;
  display: flex;
  object-fit: cover;
}
.merchantSettled_box {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.merchantSettled_left {
  color: #fff;
}
.merchantSettled_leftTitle > div:nth-of-type(1) {
  font-size: 24px;
  font-weight: 400;
  color: #ffffff;
  line-height: 36px;
}
.merchantSettled_leftTitle > div:nth-of-type(2) {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
  margin-top: 6px;
}
/* 流程 */
.process {
  width: 100%;
  padding: 10px 12px 60px;
  box-sizing: border-box;
}
.process_left {
  margin-top: 40px;
  padding-left: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.process_left > div:nth-of-type(1) {
  position: relative;
  width: 48%;
  display: flex;
  justify-content: flex-start;
}
.process_left > div:nth-of-type(2) {
  width: calc(50% - 8px);
}
.process_left > div:nth-of-type(2) img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: flex;
}
.process_leftImg {
  width: 20px;
  height: 20px;
  position: absolute;
  left: -10px;
  z-index: -1;
}
.process_leftImg img {
  width: 20px;
  height: 20px;
  display: flex;
  object-fit: cover;
}
.process_leftInfo > div:nth-of-type(1) {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 24px;
}
.process_leftInfo > div:nth-of-type(2) {
  font-size: 14px;
  color: #999999;
  line-height: 20px;
  margin-top: 5px;
}

.process_right {
  margin-top: 40px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.process_right > div:nth-of-type(1) {
  width: calc(50% - 8px);
}
.process_right > div:nth-of-type(2) {
  display: flex;
  justify-content: flex-end;
  width: 48%;
  position: relative;
}
.process_right > div:nth-of-type(1) img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: flex;
}
.process_rightImg {
  width: 20px;
  height: 20px;
  position: absolute;
  right: -10px;
  z-index: -1;
}
.process_rightImg img {
  width: 20px;
  height: 20px;
  display: flex;
  object-fit: cover;
}
.process_rightInfo > div:nth-of-type(1) {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.process_rightInfo > div:nth-of-type(2) {
  font-size: 14px;
  color: #999999;
  line-height: 20px;
  margin-top: 5px;
}
/* 免费试用 */
.application {
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px 0;
}
.application > div {
  width: 100%;
}
.application_title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.application_title > div:nth-of-type(1) {
  font-size: 24px;
  font-weight: 400;
  color: #333333;
  line-height: 36px;
}
.application_title > div:nth-of-type(2) {
  font-size: 13px;
  font-weight: 400;
  color: #999999;
  line-height: 20px;
}
.application_box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 40px;
}
.application_box > div {
  width: 100%;
}
.application_boxLeftBtn {
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  box-sizing: border-box;
}
.application_boxLeftBtn > div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  background: #47B0B4;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
}
.application_boxLeft {
  padding: 0 36px;
  width: 100%;
  box-sizing: border-box;
}
.application_boxLeft > div {
  width: 100%;
}
.application_boxLeftItem {
  height: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.application_boxLeftItem > div:nth-of-type(1) {
  font-size: 14px;
  color: #333;
  width: 25%;
  text-align: right;
}
.application_boxLeftItem input {
  border: none;
  border-bottom: 1px solid #ccc;
  width: 70%;
  outline: none;
  padding: 5px;
  background-color: #f5f5f5;
}
.application_boxRight {
  margin-top: 40px;
  width: 100%;
  padding: 0 24px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  box-sizing: border-box;
}
</style>